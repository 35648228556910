import React from "react"

import Card from "../../common/Card/card"

const MarketingServices = () => {
    return (
        <section className="container section-service">
            <div className="row space-2-bottom space-3-bottom--lg">
                <div className="col-12">
                    <div className="section-service-title green">Servicios Marketing Digital</div>
                    <div className="section-service-subtitle">Planes y estrategias de Marketing Digital 360</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-web-page"
                        cardIcoColor="green"
                        cardLink="Páginas web"
                        cardLinkUrl="/marketing-digital/paginas-web"
                        cardText="Creamos webs corporativas responsive para dar a conocer tu negocio"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-ecommerce"
                        cardIcoColor="green"
                        cardLink="Tiendas Online"
                        cardLinkUrl="/marketing-digital/tiendas-online"
                        cardText="Desarrollamos tiendas online dinámicas para que vendas tus productos"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-blogs"
                        cardIcoColor="green"
                        cardLink="Blogs corporativos"
                        cardLinkUrl="/marketing-digital/blogs-corporativos"
                        cardText="Diseñamos blogs para potenciar y explicar tus servicios y/o productos"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-sem"
                        cardIcoColor="green"
                        cardLink="Posicionamiento SEM"
                        cardLinkUrl="/marketing-digital/posicionamiento-sem"
                        cardText="Administramos campañas de Google &amp; Social Ads para incrementar tus ventas"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-seo"
                        cardIcoColor="green"
                        cardLink="Posicionamiento SEO"
                        cardLinkUrl="/marketing-digital/posicionamiento-seo"
                        cardText="Posicionamos webs (SEO) para aumentar tu tráfico y visibilidad web"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-social-media"
                        cardIcoColor="green"
                        cardLink="Redes sociales"
                        cardLinkUrl="/marketing-digital/redes-sociales"
                        cardText="Gestionamos redes sociales de empresas para cuidar tu reputación online"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-content-marketing"
                        cardIcoColor="green"
                        cardLink="Content Marketing"
                        cardLinkUrl="/marketing-digital/content-marketing"
                        cardText="Generamos contenido SEO para posicionar tus productos y servicios en los buscadores"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-online-audits"
                        cardIcoColor="green"
                        cardLink="Auditorías Online"
                        cardLinkUrl="/marketing-digital/auditorias-online"
                        cardText="Auditamos páginas webs y tiendas online para analizar cuál es tu presencia digital"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-mailing"
                        cardIcoColor="green"
                        cardLink="Email Marketing"
                        cardLinkUrl="/marketing-digital/email-marketing"
                        cardText="Lanzamos campañas de emarketing y newsletter para fidelizar a tus clientes"
                    />
                </div>
            </div>
        </section>
    )
}

export default MarketingServices
import React from "react"

import Card from "../../common/Card/card"

const GraphicProductionServices = () => {
    return (
        <section className="container section-service">
            <div className="row space-2-bottom space-3-bottom--lg">
                <div className="col-12">
                    <div className="section-service-title blue">Servicios de Impresión</div>
                    <div className="section-service-subtitle">Producción gráfica publicitaria</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-offset-print"
                        cardIcoColor="blue"
                        cardLink="Impresión Offset"
                        cardLinkUrl="/produccion-grafica/impresion-offset"
                        cardText="Imprimimos grandes tirajes con el sistema de impresión Offset"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-digital-print"
                        cardIcoColor="blue"
                        cardLink="Impresión Digital"
                        cardLinkUrl="/produccion-grafica/impresion-digital"
                        cardText="Imprimimos en diferentes soportes publicitarios con tecnología digital"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-merchandising"
                        cardIcoColor="blue"
                        cardLink="Merchandising"
                        cardLinkUrl="/produccion-grafica/merchandising"
                        cardText="Personalizamos gadgets publicitarios con la marca de tu empresa"
                    />
                </div>
            </div>
        </section>
    )
}

export default GraphicProductionServices
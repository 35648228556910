import React, { Component } from "react"
import { Link } from 'gatsby'

import "./button.scss"

class Button extends Component {
    render() {

        return (
            <Link className={`ibx-button ` + this.props.color} to={this.props.to} title={this.props.text}>{this.props.text}</Link>
        )
    }
}

export default Button
import React, { Component } from "react"

import Button from "../../common/Button/button"

import "./callToAction.scss"

class CallToActionSection extends Component {
    render() {
        return (
            <section className={`section-call-to-action section-call-to-action-${this.props.color}`}>
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-md-8 col-lg-10 col-xl-10 text-center">
                            <div className="section-call-to-action__title">
                                {this.props.title}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center space-2-bottom">
                        <div className="col-12 col-md-10 col-lg-10 col-xl-10 text-center">
                            <div className="section-call-to-action__subtitle">
                                {this.props.text}
                            </div>
                        </div>
                    </div>
                    <div className="row space-1-bottom">
                        <div className="col-12 text-center">
                            <Button
                                color="white"
                                to={this.props.buttonUrl}
                                text={this.props.button}
                            />
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}

export default CallToActionSection
import React from "react"

import Layout from "../components/common/Layout/layout"
import SEO from "../components/common/SEO/seo"
import BannerPage from "../components/banner/BannerPage/bannerPage"
import MarketingServices from "../components/section/MarketingServices/marketingServices"
import VideoServices from "../components/section/AudiovisualProductionServices/audiovisualProductionServices"
import WebDesignServices from "../components/section/GraphicDesignServices/graphicDesignServices"
import PrintServices from "../components/section/GraphicProductionServices/graphicProductionServices"
import CallToAction from "../components/section/CallToAction/callToAction"

const SitemapPage = () => {

    return (
        <Layout>
            <SEO
                title="Mapa Web"
                description="Accede a tos los contenidos de tu agencia de marketing digital especializada en posicionamiento web, SEM, diseño web, diseño gráfico, producción gráfica y producción audiovisual."
            />

            <BannerPage
                title="Mapa Web"
                text="Accede a todos los contenidos de IBX"
                color="green"
            />

            <MarketingServices />
            <hr className="space-2-bottom space-3-bottom--lg" />
            <WebDesignServices />
            <hr className="space-2-bottom space-3-bottom--lg" />
            <PrintServices />
            <hr className="space-2-bottom space-3-bottom--lg" />
            <VideoServices />

            <CallToAction
                color="green"
                title="¿Necesitas un plan de marketing digital para tu empresa?"
                text="Somos tu Agencia de Marketing Digital"
                button="Pide presupuesto ¡Gratis!"
                buttonUrl="/contacto"
            />



        </Layout>
    )

}
export default SitemapPage

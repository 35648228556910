import React from "react"

import Card from "../../common/Card/card"

const GraphicDesignServices = () => {
    return (
        <section className="container section-service">
            <div className="row space-2-bottom space-3-bottom--lg">
                <div className="col-12">
                    <div className="section-service-title pink">Servicios Diseño Gráfico</div>
                    <div className="section-service-subtitle">Creación de la imagen que define tu marca</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-branding"
                        cardIcoColor="pink"
                        cardLink="Branding"
                        cardLinkUrl="/diseno-grafico/branding"
                        cardText="Conceptualizamos logos y la identidad corporativa de tu marca y negocio"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-advertising-design"
                        cardIcoColor="pink"
                        cardLink="Diseño comercial"
                        cardLinkUrl="/diseno-grafico/publicitario"
                        cardText="Diseñamos materiales gráficos para promocionar tu marca, productos o servicios"
                    />
                </div>
                <div className="col-md-4 col-sm-12 space-2-bottom space-3-bottom--lg">
                    <Card
                        cardIco="ico-editorial-design"
                        cardIcoColor="pink"
                        cardLink="Diseño editorial"
                        cardLinkUrl="/diseno-grafico/editorial"
                        cardText="Maquetamos cualquier publicación editorial para hacer despuntar tu empresa"
                    />
                </div>
            </div>
        </section>
    )
}

export default GraphicDesignServices